<svelte:options immutable={true} /><script>import { createEventDispatcher, onMount } from 'svelte';
import { icons } from './icons';
import { delegate } from 'tippy.js';
const dispatch = createEventDispatcher();
let toolbar;
export let context;
export let split;
export let activeTab;
export let fullscreen;
export let sidebar;
export let locale;
export let actions;
$: tocActive = sidebar === 'toc';
$: helpActive = sidebar === 'help';
$: writeActive = activeTab === 'write';
$: previewActive = activeTab === 'preview';
$: rightActions = [
    // {
    //   title: 'Key binding',
    //   icon: icons.keyboard,
    //   handler: {
    //     type: 'dropdown',
    //     actions: [
    //       {
    //         title: 'Normal',
    //         handler: {
    //           type: 'action',
    //           click() {
    //             dispatch('key', 'default');
    //           },
    //         },
    //       },
    //       {
    //         title: 'Vim',
    //         handler: {
    //           type: 'action',
    //           click() {
    //             dispatch('key', 'vim');
    //           },
    //         },
    //       },
    //       {
    //         title: 'Emacs',
    //         handler: {
    //           type: 'action',
    //           click() {
    //             dispatch('key', 'emacs');
    //           },
    //         },
    //       },
    //     ],
    //   },
    // },
    {
        title: tocActive ? locale.closeToc : locale.toc,
        icon: icons.toc,
        handler: {
            type: 'action',
            click() {
                dispatch('click', 'toc');
            },
        },
        active: tocActive,
    },
    {
        title: helpActive ? locale.closeHelp : locale.help,
        icon: icons.help,
        handler: {
            type: 'action',
            click() {
                dispatch('click', 'help');
            },
        },
        active: helpActive,
    },
    {
        title: writeActive ? locale.exitWriteOnly : locale.writeOnly,
        icon: icons.left,
        handler: {
            type: 'action',
            click() {
                dispatch('tab', 'write');
            },
        },
        active: writeActive,
        hidden: !split,
    },
    {
        title: previewActive ? locale.exitPreviewOnly : locale.previewOnly,
        icon: icons.right,
        handler: {
            type: 'action',
            click() {
                dispatch('tab', 'preview');
            },
        },
        active: previewActive,
        hidden: !split,
    },
    {
        title: fullscreen ? locale.exitFullscreen : locale.fullscreen,
        icon: fullscreen ? icons.fullscreenOff : icons.fullscreenOn,
        handler: {
            type: 'action',
            click() {
                dispatch('click', 'fullscreen');
            },
        },
    },
    {
        title: locale.source,
        icon: icons.source,
        handler: {
            type: 'action',
            click() {
                window.open('https://github.com/bytedance/bytemd');
            },
        },
    },
];
const tippyClass = 'bytemd-tippy';
const tippyClassRight = 'bytemd-tippy-right';
const tippyPathKey = 'bytemd-tippy-path';
function getPayloadFromElement(e) {
    var _a, _b;
    const paths = (_b = (_a = e
        .getAttribute(tippyPathKey)) === null || _a === void 0 ? void 0 : _a.split('-')) === null || _b === void 0 ? void 0 : _b.map((x) => parseInt(x, 10));
    if (!paths)
        return;
    // if (!paths) {
    //   return {
    //     paths: [],
    //     item: {
    //       title: 'test',
    //       handler: actions,
    //     },
    //   };
    // }
    let item = {
        title: '',
        handler: {
            type: 'dropdown',
            actions: e.classList.contains(tippyClassRight) ? rightActions : actions,
        },
    };
    paths === null || paths === void 0 ? void 0 : paths.forEach((index) => {
        var _a;
        if (((_a = item.handler) === null || _a === void 0 ? void 0 : _a.type) === 'dropdown') {
            item = item.handler.actions[index];
        }
    });
    return { paths, item: item };
}
let delegateInstance;
function init() {
    delegateInstance = delegate(toolbar, {
        target: `.${tippyClass}`,
        onCreate({ setProps, reference }) {
            const payload = getPayloadFromElement(reference);
            if (!payload)
                return;
            const { item, paths } = payload;
            const { handler } = item;
            if (!handler)
                return;
            if (handler.type === 'action') {
                setProps({
                    content: item.title,
                    onHidden(ins) {
                        ins.destroy();
                    },
                });
            }
            else if (handler.type === 'dropdown') {
                // dropdown
                const dropdown = document.createElement('div');
                dropdown.classList.add('bytemd-dropdown');
                if (item.title) {
                    const dropdownTitle = document.createElement('div');
                    dropdownTitle.classList.add('bytemd-dropdown-title');
                    dropdownTitle.appendChild(document.createTextNode(item.title));
                    dropdown.appendChild(dropdownTitle);
                }
                handler.actions.forEach((subAction, i) => {
                    var _a;
                    const dropdownItem = document.createElement('div');
                    dropdownItem.classList.add('bytemd-dropdown-item');
                    dropdownItem.setAttribute(tippyPathKey, [...paths, i].join('-'));
                    if (((_a = subAction.handler) === null || _a === void 0 ? void 0 : _a.type) === 'dropdown') {
                        dropdownItem.classList.add(tippyClass);
                    }
                    if (reference.classList.contains(tippyClassRight)) {
                        dropdownItem.classList.add(tippyClassRight);
                    }
                    // div.setAttribute('data-tippy-placement', 'right');
                    dropdownItem.innerHTML = `${subAction.icon
                        ? `<div class="bytemd-dropdown-item-icon">${subAction.icon}</div>`
                        : ''}<div class="bytemd-dropdown-item-title">${subAction.title}</div>`;
                    dropdown.appendChild(dropdownItem);
                });
                setProps({
                    allowHTML: true,
                    showOnCreate: true,
                    theme: 'light-border',
                    placement: 'bottom-start',
                    interactive: true,
                    interactiveDebounce: 50,
                    arrow: false,
                    offset: [0, 4],
                    content: dropdown.outerHTML,
                    onHidden(ins) {
                        ins.destroy();
                    },
                    onCreate(ins) {
                        ;
                        [
                            ...ins.popper.querySelectorAll('.bytemd-dropdown-item'),
                        ].forEach((el, i) => {
                            var _a;
                            const actionHandler = (_a = handler.actions[i]) === null || _a === void 0 ? void 0 : _a.handler;
                            if ((actionHandler === null || actionHandler === void 0 ? void 0 : actionHandler.type) === 'action') {
                                const { mouseenter, mouseleave } = actionHandler;
                                if (mouseenter) {
                                    el.addEventListener('mouseenter', () => {
                                        mouseenter(context);
                                    });
                                }
                                if (mouseleave) {
                                    el.addEventListener('mouseleave', () => {
                                        mouseleave(context);
                                    });
                                }
                            }
                        });
                    },
                });
            }
        },
    });
}
onMount(() => {
    init();
});
function handleClick(e) {
    var _a, _b;
    const target = e.target.closest(`[${tippyPathKey}]`);
    if (!target)
        return;
    const handler = (_b = (_a = getPayloadFromElement(target)) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.handler;
    if ((handler === null || handler === void 0 ? void 0 : handler.type) === 'action') {
        handler.click(context);
    }
    delegateInstance === null || delegateInstance === void 0 ? void 0 : delegateInstance.destroy();
    init();
}
</script><div class="bytemd-toolbar" bind:this={toolbar} on:click={handleClick}><div class="bytemd-toolbar-left">{#if split}{#each actions as item, index}{#if item.handler}<div
            class={['bytemd-toolbar-icon', tippyClass].join(' ')}
            bytemd-tippy-path={index}
          >{@html item.icon}</div>{/if}{/each}{:else}<div
        on:click={() => dispatch('tab', 'write')}
        class="bytemd-toolbar-tab"
        class:bytemd-toolbar-tab-active={activeTab !== 'preview'}
      >{locale.write}</div><div
        on:click={() => dispatch('tab', 'preview')}
        class="bytemd-toolbar-tab"
        class:bytemd-toolbar-tab-active={activeTab === 'preview'}
      >{locale.preview}</div><!-- <div class={['bytemd-toolbar-icon', tippyClass].join(' ')}>{@html icons.more}</div> -->{/if}</div><div class="bytemd-toolbar-right">{#each rightActions as item, index}{#if !item.hidden}<div
          class={['bytemd-toolbar-icon', tippyClass, tippyClassRight].join(' ')}
          class:bytemd-toolbar-icon-active={item.active}
          bytemd-tippy-path={index}
        >{@html item.icon}</div>{/if}{/each}</div></div>
